import React from "react";
import Lottie from 'react-lottie';
import { Grid } from '@mui/material';
import teamwork from "../../assets/lotties/teamwork.json"
import empDiscussion from "../../assets/lotties/employee-discussion.json"
import teamworkCooperating from "../../assets/lotties/teamwork-cooperating.json"
import Elephant from "../../assets/icons/elephant-icon.svg";
import Globe from "../../assets/icons/globe-icon.svg";
import YogaElephant from "../../assets/icons/yoga-elephant-icon.svg";
import Flower from "../../assets/icons/flower-icon.svg";
import Quote from "../../assets/icons/quote-icon.svg";
import ScrollDown from "../../assets/icons/scroll-down-icon.svg";
import TeamMate from "./TeamMate";
import Testimonials from "../Testimonials";
// import TeamWork from "../TeamWork";
import TechStack from "./TechStack";
import Humility from '../../components/JoinUs/Humility';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const empDiscussionAnimation = { ...defaultOptions, animationData: empDiscussion }
const teamWorkAnimation = { ...defaultOptions, animationData: teamwork }



export default function HomeDetails() {
  return <div className="home-container">
    <header className="hero df fc">
      <div className="header-container">
        <h1 className="serif remote">The HUMBLE</h1>
        <div className="developer-root">
          <div className="df developer">
            <span><Flower /></span>
            <h1 className="serif">Remote Team</h1>
          </div>
          <span className="serif trust df">you can TRUST!</span>
        </div>
        <div className="arrow-container">
          <p>We help you find top remote engineering talent from India, who are not only skilled but also exceptional human beings, ready to become a valued part of your remote workforce.
          </p>
          <button className="scroll-hint pen">
            <ScrollDown />
          </button>
        </div>
      </div>
    </header>

    <div className="home-first-lottie">
      <Lottie options={teamWorkAnimation} />
    </div>



    <div className="home-info1 vetted-dev-section df jcb center">
      <Grid container spacing={{ md: 3, xs: 6 }} className="">
        <Grid item md={4} xs={12} sx={{ flexDirection: "column" }} className="df jcb text-center small-svg">
          <Elephant />
          <div className="svg-desc">
            <span>Vetted Developers</span>
            <desc>Our hiring process exceeds industry standards with multi-level assessments, ensuring only top-tier talent is selected for your team.</desc>
          </div>
        </Grid>
        <Grid item md={4} xs={12} sx={{ flexDirection: "column" }} className="df jcb text-center small-svg">
          <Globe />
          <div className="svg-desc">
            <span>Remote Experts</span>
            <desc>We’re remote by choice, long before it was the norm, and our experience guarantees the highest performance in remote environments.</desc>
          </div>
        </Grid>
        <Grid item md={4} xs={12} sx={{ flexDirection: "column" }} className="df jcb text-center small-svg">
          <YogaElephant />
          <div className="svg-desc">
            <span>Zero Drama, Only Karma</span>
            <desc>We eliminate obstacles and ensure we don’t create new ones. Our goal is to seamlessly integrate as an asset, driving results without complications.</desc>
          </div>
        </Grid>
      </Grid>
    </div>
    <TechStack />
    
    <TeamMate />

    <Humility isClient />

    <div className="care">
      <blockquote>
        <Quote />
        <div className="header">
          <h5 className="serif">We are your team</h5>
          <div>
            <h5 className="serif c-red">Working remotely</h5>
          </div>
        </div>

      </blockquote>
      <div className="humility">
        <div className="content">
          <p>Partnering with Zowork has completely changed our perspective on remote collaboration. Their team integrated seamlessly with ours, demonstrating humility and dedication at every step. They onboarded quickly without missing a beat on quality, and their commitment to our project's success matched our own. Zowork doesn't just feel like a vendor—they feel like a trusted extension of our team. </p>
            <p><strong>Miles Romney, Founder MAST</strong></p>
        </div>
      </div >
      <Lottie options={empDiscussionAnimation} />
    </div>

    <Testimonials />
  </div>
}
