import React, { useEffect } from "react";
import DefaultLayout from "../components/Layouts/DefaultLayout";
import Seo from '../components/seo';
import HomeContent from '../components/Home';

export default function Home(props) {
  useEffect(() => {
    window.navigateFrom = 'home';
  }, []);

  return <DefaultLayout {...props}>
    <Seo
      title="Zowork | Digital product engineering leader" description=" A digital product engineering leader, Zowork drives technology-led business breakthroughs for industry leaders and challengers through agility and innovation. We find you high quality developers in the shortest timeline. "
    />
    <HomeContent />
  </DefaultLayout>
}
