import React from "react";
import UpwardQuotes from "../../assets/icons/upward-quotes.svg";
import LegacyAvatar1 from "../../assets/icons/legacy-avatar-1.svg";
import LegacyAvatar2 from "../../assets/icons/legacy-avatar-2.svg";
import BretImg from "../../assets/images/testimonial/bret_larsen.jpeg";
import LudaImg from "../../assets/images/testimonial/luda_ooyala.jpeg";
import StephenImg from "../../assets/images/testimonial/stephanek.jpeg";

const data = [
    {
        author: "Matt",
        designation: "CEO",
        company: "",
        message: "We’ve had a full-time developer with Zowork for over 6mos and he’s definitely part of the team now. he helped develop our api, web and mobile app. definitely been a great experience and we’ll continue working with him.",
        avatar: StephenImg
    },
    {
        author: "Bret Larson",
        designation: "CEO",
        company: "evisit",
        message: "As a startup, your needs tend to vary widely from day-to-day. Zowork has consistently been there to support us, regardless of the ask, and delivered high-quality work. In our experience, finding a reliable 3rd-party software development firm is not an easy task and Zowork has been, hands down, simply the best. If you’re in the market for a true partner who can deliver, Zowork is that partner",
        avatar: BretImg
    },
    {
        author: "Linda",
        designation: "Startup Founder",
        company: "",
        message: "Zowork is a reliable partner who understands our technology and our culture. They consistently meet and surpass our expectations; always delivering quality projects on time, on strategy and on budget.",
        avatar: LudaImg
    },
];

export default function ReviewCardList() {
    const leftCardList = data.filter((_, index) => (index + 1) % 2 === 0);
    const rightCardList = data.filter((_, index) => (index + 1) % 2 === 1);
    return (
        <div className="review-card df">
            <div className="left-container">
                {leftCardList.map(renderCard)}
            </div>
            <div className="right-container">
                {rightCardList.map(renderCard)}
            </div>
        </div>
    );

    function renderCard(data) {
        return (
            <div className="card df fc jcb">
                <p>"{data.message}"</p>
                <div className="df jcb aic">
                    <div className="df aic">
                        <div className="icon-avatar">
                            <img
                                src={data.avatar}
                                alt="avatar"
                            />
                        </div>
                        <div>
                            <div className="author">{data.author}</div>
                            <div className="designation">{data.designation}{data.company === ''? '':', '}{data.company}</div>
                        </div>
                    </div>
                    <div className="icon-upward-quotes">
                        <UpwardQuotes />
                    </div>
                </div>
            </div>
        );
    }
}