import React from "react";
import { Grid } from '@mui/material';
import LeftToRightArrow from "../../../assets/icons/connect-left-right-icon.svg";
import RightToLeftArrow from "../../../assets/icons/connect-right-left-icon.svg";
import FindDevStepOne from "../../../assets/icons/find_dev_series/find_dev_step_1.svg";
import FindDevStepTwo from "../../../assets/icons/find_dev_series/find_dev_step_2.svg";
import FindDevStepThree from "../../../assets/icons/find_dev_series/find_dev_step_3.svg";
import FindDevStepFour from "../../../assets/icons/find_dev_series/find_dev_step_4.svg";
import FindDevStepFive from "../../../assets/icons/find_dev_series/find_dev_step_5.svg";

export default function TeamMate() {
    return <div className="team-mate">
        <Grid container>
            <Grid xs={12}>
                <header>
                    <h5 className="title serif">Find your</h5>
                    <div>
                        <h5 className="title serif c-red">next team Member</h5>
                    </div>
                </header>
            </Grid>
        </Grid>
        <div className="steps">
            <div className="df aic margin-around step1">
                <FindDevStepOne />
                <p>
                    Looking for a remote developer who can join your team and take your product to the next level?
                </p>
            </div>

            <div className="df aic margin-around step2">
                <div className="df">
                    <div className="lr-direction"><LeftToRightArrow /></div>
                    <FindDevStepTwo />
                </div>
                <p>
                    Connect with us, and we'll help you identify the skills you're searching for in a potential team member.
                </p>
            </div>

            <div className="df aic margin-around step3">
                <FindDevStepThree />
                <p>
                    Our expertise lies in understanding your requirements and finding the best match based on your needs.
                </p>
                <div className="rl-direction"><RightToLeftArrow /></div>
            </div>

            <div className="df aic margin-around step4">
                <span className="lr-direction"><LeftToRightArrow /></span>
                <FindDevStepFour />
                <p>
                    We begin our vetting process keeping in mind your goals and how a potential team member can help you accomplish them.
                </p>
            </div>

            <div className="df aic step5">
                <FindDevStepFive />
                <p>
                Your ideal developer joins your team and makes sure to excel with their contributions in no time.
                </p>
                <span className="rl-direction"><RightToLeftArrow /></span>
            </div>
        </div>
    </div>
}
