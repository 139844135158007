import React from "react";
import ReactImg from "../../../assets/images/tech/react.png";
import ReduxImg from "../../../assets/images/tech/redux.png";
import ReactNativeImg from "../../../assets/images/tech/reactnative.png";
import NodeImg from "../../../assets/images/tech/node.png";
import MySqlImg from "../../../assets/images/tech/mysql.png";
import RailsImg from "../../../assets/images/tech/rails.png";
import MongoDbImg from "../../../assets/images/tech/mongodb.png";
import FlutterImg from "../../../assets/images/tech/flutter.png";
import DontNetImg from "../../../assets/images/tech/dotnet.png";
import CSharpImg from "../../../assets/images/tech/csharp.png";
import FigmaImg from "../../../assets/images/tech/figma.png";

const techData = [
  { name: "React JS", imageUrl: ReactImg },
  { name: "Redux", imageUrl: ReduxImg },
  { name: "React Native", imageUrl: ReactNativeImg },
  { name: "Node.js", imageUrl: NodeImg },
  { name: ".NET Core", imageUrl: DontNetImg },
  { name: "MongoDB", imageUrl: MongoDbImg },
  { name: "Flutter", imageUrl: FlutterImg },
  { name: "Figma", imageUrl: FigmaImg },
  { name: "Ruby on Rails", imageUrl: RailsImg },
  { name: "CSharp", imageUrl: CSharpImg },
  { name: "MySQL", imageUrl: MySqlImg }
];

export default function TechStack() {
  // Spreading techData twice to create the loop
  const scrollingData = [...Array(4)].flatMap(() => techData);

  return (
    <section className="tech-stack">
      <h2 className="title">Techs We Use to Deliver Excellence</h2>
      <div className="scrolling-techs">
        <div className="scrolling-content">
          {scrollingData.map((tech, index) => (
            <div className="img-container" key={index}>
              <img src={tech.imageUrl} alt={tech.name} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
