import React from "react";
import HappyFace from "../../assets/icons/happy-face.svg";
import Star from "../../assets/icons/star.svg";
import ReviewCardList from "../ReviewCardList";

export default function Testimonials() {
    return (
        <div className="testimonial df jcb fc">
            <div className="header df jcb fr">
                <div>
                    <h1 className="title serif">Happy Clients</h1>
                    <p className="serif">Kind words from our Clients</p>
                </div>
                <div className="icon-happy-face">
                    <HappyFace />
                </div>
            </div>
            <ReviewCardList />
            <div className="icon-star">
                <Star />
            </div>
        </div>
    );
}